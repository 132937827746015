.home{
    background-color: white;
    min-height: 500px;
    /* background-image: url('../../../assets/images/backgound.jpg'); */
}
.home .food-container{
    display: flex;
    justify-content: center;
}
.food-pic{
    width: 40%;
    padding: 0px;
    margin: 122px 10px;
    box-shadow: 1px 1px 4px 2px white;
    height: auto;
    border-radius: 10px;
}
.food-name{
    text-align: center;
    width: 100%;
    background: white;
    color: #ff7373;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0px;
    box-shadow: 1px 1px 4px 2px white;
    border-radius: 10px 10px 0px 0px;
}
.food-pic img{
    width: 100%;
    border-radius: 10px;
    background: #ffe3ae8c;
}
.food-pic #fade-in, .food-name{
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}
.default-img{
    background: #ffe3aecf;
}
#loading-img{
    cursor: wait;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
    
@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
    
@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
    
@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
    
@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}