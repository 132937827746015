.search-btn-container{
    position: fixed;
    top: 22%;
    left: 5%;
    display: grid;
    justify-content: center;
    width: 20%;
}
.search-btns div{
    color: white;
    background-color:rgba(255, 0, 0, 0.548);
    padding: 8px 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 2px white;
    animation-name: btn-click;
    animation-duration: 2s;
    text-align: center;
    margin: 5px 0px;
    cursor: pointer;
}
.search-btns div:active{
    color:rgba(255, 0, 0, 0.548);
    background-color: white;
    transform: translate(0px, 5px);
}

@keyframes btn-click {
    from {
        background-color: white;
        color:white;
    }
    to {
        color: white;
        background-color:rgba(255, 0, 0, 0.548);
    }
}