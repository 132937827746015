body{
    margin:0;
    padding:0;
}
.header{
    color:white;
    text-align: center;
    padding: 20px 0px;
    background: rgb(247 80 80);
    font-size: 35px;
    font-weight: bold;
    opacity: 3;
    width: 100%;
    position: fixed;
    animation-name: header-anm;
    animation-duration: 1s;
}

@keyframes header-anm {
    from {
       background-color: white;

    }
    to {
        background-color: rgb(247 80 80);
    }
}